import React from "react";
import { Link } from 'gatsby'
import appData from "data/app.json";

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="item md-mb50">
              <ul>
                <li>
                  <div className="logo">
                    <img src={appData.lightLogo} alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item md-mb50">
              <ul>
                <li>
                  <span className="icon pe-7s-map-marker"></span>
                  <div className="cont">
                    <h6>Office Address</h6>
                    <p>2360 Hopewell Plantation Dr, Milton GA 30534</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item md-mb50">
              <ul>
                <li>
                  <span className="icon pe-7s-mail"></span>
                  <div className="cont">
                    <h6>Email Us</h6>
                    <p><a href="mailto:contact@cc.agency">contact@cc.agency</a></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item md-mb50">
              <ul>
                <li>
                  <span className="icon pe-7s-call"></span>
                  <div className="cont">
                    <h6>Call Us</h6>
                    <p>+1 833 270 3304</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="item">
              <div className="copy-right">
                <p>
                  © 2023 Creative Clarity Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
